<template>
		<mw-dialog
			v-model="dialog"
			submit-btn
			@submit="save"
			v-if="headers.length"
			title="Column Visibility Settings"
		>
			<template v-slot:button>
				<v-btn color="primary" @click="dialog = true" block>
					Manage Columns
				</v-btn>
			</template>
			<template v-slot:dialog-body>
				<v-btn @click="toggleAll" color="primary">Toggle All</v-btn>
				<v-checkbox
					multiple
					v-model="selected"
					v-for="header in headers"
					:key="header.value"
					:label="header.text"
					:value="header.value"
					hide-details
				>
				</v-checkbox>
			</template>
		</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "ManageColumns",
	inject: ["projectId"],
	data: () => {
		return {
			dialog: false,
			selected: [],
		};
	},
	props: ["headers"],
	components: {
		MwDialog,
	},
	computed: {
		default(){			
			return this.$store.getters['tableHeaders/projectColumns'](this.projectId);			
		},
		settings() {
			let settings = this.$store.getters["auth/tableHeaders"](
				this.projectId
			);
			return settings.length ? settings : this.default;
		},
		
	},
	methods: {
		toggleAll(){
			if( this.selected.length ){
				this.selected = []
			}
			else{
				this.selected = this.headers.map( h => h.value );
			}
		},
		save() {
			this.$store.dispatch("auth/updateTableHeaders", {
				id: this.projectId,
				cols: this.selected
			});
			this.dialog = false;
		},
	},
	watch: {
		settings: {
			immediate: true,
			handler(value) {
				this.selected = value;
			},
		},
	},
};
</script>
