<template>
	<v-sheet flat v-if="project">
		<h4>{{project.internal_name}}</h4>
		<appointment-table
			:id="id"
			:headers="headers"
			@focus="focusOn"
			:focus-appointment="focusAppointment"
			:focus-appointment-participant="focusAppointmentParticipant"
		></appointment-table>
		<secondary-navigation show-back>
			<template v-slot:main>
				<v-card flat>
					<v-card-text>
						<manage-columns :headers="headers"></manage-columns>
						<template v-if="focusAppointment">
							<v-list-item>
								<v-list-item-content></v-list-item-content>
								<v-list-item-action>
									<v-btn @click="unfocus" icon
										><v-icon>mdi-close</v-icon></v-btn
									>
								</v-list-item-action>
							</v-list-item>
							<edit-appointment
								@close="unfocus"
								:id="focusAppointment"
								@addParticipant="
									(v) => (focusAppointmentParticipant = v)
								"
							></edit-appointment>
							<delete-button
								@close="unfocus"
								storeModule="appointments"
								:id="focusAppointment"
								block
								:small="false"
								:text="false"
								button-text="delete appointment"
							></delete-button>
						</template>
						<template v-if="focusAppointmentParticipant">
							<div class="mb-2">
								<edit-appointment-participant
									@close="focusAppointmentParticipant = null"
									v-if="focusAppointmentParticipant"
									:id="focusAppointmentParticipant"
								></edit-appointment-participant>
							</div>
							<delete-button
								storeModule="appointmentParticipants"
								:id="focusAppointmentParticipant"
								block
								:small="false"
								:text="false"
								button-text="delete participant"
							></delete-button>
						</template>
						<template v-if="noFocus">
							<v-btn color="primary" block @click="newAppointment">
								New Appointment
							</v-btn>
						</template>
					</v-card-text>
				</v-card>
			</template>
			<template v-slot:append>
				<edit-project-notes v-model="id"></edit-project-notes>
			</template>
		</secondary-navigation>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import SecondaryNavigation from "@c/navigation/Secondary";
import EditAppointment from "@c/edits/EditAppointment.vue";
import EditAppointmentParticipant from "@c/edits/EditAppointmentParticipant.vue";
import AppointmentTable from "@c/appointment-table/AppointmentTable.vue";
import ManageColumns from "@c/appointment-table/ManageColumns.vue";
import DeleteButton from "@c/buttons/DeleteButton.vue";
import EditProjectNotes from '@c/edits/EditProjectNotes';

export default {
	name: "EditProjectAppointments",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
		appointmentId: { type: String },
		appointmentParticipantId: { type: String },
	},
	provide() {
		return {
			projectId: this.id,
		};
	},
	data: () => {
		return {
			focusAppointment: null,
			focusAppointmentParticipant: null,
		};
	},
	computed: {
		title(){
			return this.project.internal_name || null;
		},
		noFocus() {
			return this.focusAppointment == null;
		},
		project() {
			return this.$store.state.projects.data[this.id];
		},
		customHeaders() {
			const self = this;
			if (!self.project) {
				return [];
			}
			return self.project.custom_fields.map((f) => ({
				text: self.mwutils.prettyPrint(f, "TITLE"),
				value: f,
				source: "participant",
				expanded: false,
				display: "default",
				filterable: true,
			}));
		},
		headers() {
			return this.$store.getters["tableHeaders/projectColumns"](this.id);
			// return headers.concat(this.customHeaders);
		},
	},
	components: {
		SecondaryNavigation,
		EditAppointment,
		AppointmentTable,
		ManageColumns,
		DeleteButton,
		EditProjectNotes,
		EditAppointmentParticipant,
	},
	methods: {
		setTitle(v) {
			this.$store.commit("SET_TITLE", v  );
		},
		newAppointment() {
			const self = this;
			let doc = {
				project: self.id,
			};
			self.$store.dispatch("appointments/insert", doc).then((id1) => {
				self.$store
					.dispatch("appointmentParticipants/insert", { appointment: id1 })
					.then((id2) => {
						self.focusAppointment = id1;
						self.focusAppointmentParticipant = id2;
					});
			});
		},
		focusOn(v) {
			this.focusAppointment = v.appointment_id;
			if (v.have_participant) {
				this.focusAppointmentParticipant = v.participant_id;
			} else {
				this.focusAppointmentParticipant = null;
			}
		},
		unfocus() {
			this.focusAppointment = null;
			this.focusAppointmentParticipant = null;
		},
	},
	watch: {
		title: {
			immediate: true, 
			handler(v){
				if( v ){

					this.setTitle(v);
				}
			}
		}
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
		this.$store.dispatch("appointments/fetchByProject", this.id);

		if (this.appointmentId) {
			this.focusAppointment = this.appointmentId;
		}
		if (this.appointmentParticipantId) {
			this.focusAppointmentParticipant = this.appointmentParticipantId;
		}
	},
};
</script>
