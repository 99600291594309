<template>
	<td
		:key="column.value"
		v-if="displayCell"
		:class="{ focused: focused }"
		:rowspan="column.source == 'appointment' ? item.rowspan : 1"
	>
		<template v-if="displayContents">
			<span v-if="column.display == 'default'">{{
				item[column.value]
			}}</span>
			<v-component v-else :is="column.display" :column="column" :item="item"></v-component>
		</template>
	</td>
</template>

<style lang="less">
td.focused {
	background: #eeeeee !important;
}
</style>
<script>
export default {
	name: "AppointmentCell",
	props: {
		column: { type: Object },
		item: { type: Object },
		expanded: { type: Boolean },
		focusAppointment: { type: String },
		focusAppointmentParticipant: { type: String },
	},
	computed: {
		focused() {
			if (!this.item.have_participant) {
				return this.focusAppointment == this.item.appointment_id;
			} else {
				if (this.column.source == "appointment") {
					return this.focusAppointment == this.item.appointment_id;
				} else {
					return (
						this.focusAppointmentParticipant ==
							this.item.participant_id &&
						this.focusAppointment == this.item.appointment_id
					);
				}
			}
		},
		displayCell() {
			if (this.expanded) {
				return true;
			}
			if (this.column.source == "participant") {
				return true;
			}
			return this.item.i == 0;
		},
		displayContents() {
			if (this.column.source == "appointment") {
				return true;
			}
			return this.item.have_participant;
		},
	},
	components: {
		checkbox: () => import("./CheckboxCell.vue"),
		prettyPrint: () => import("./StringCell.vue"),
		date: () => import("./DateCell.vue"),
		user: () => import("./UserCell.vue"),
		click_link: () => import("./LinkCell.vue"),
		chip: () => import("./ChipCell.vue"),
		consent: () => import("./ConsentField.vue"),
	},
};
</script>
