<template>
	<v-sheet>
		<v-btn @click="rotated = !rotated" icon>
			<v-icon>mdi-rotate-right</v-icon>
		</v-btn>
		<v-data-table
			:sort-by.sync="sortBy"
			:mobile-breakpoint="mobileBreakpoint"
			:sort-asc="true"
			:headers="columns"
			:items="rows"
			item-key="id"
			:items-per-page="100"
			@click:row="(v) => $emit('focus', v)"
		>
			<template
				v-for="column in columns"
				v-slot:[`item.${column.value}`]="{ item }"
			>
				<appointment-cell
					:focus-appointment="focusAppointment"
					:focus-appointment-participant="focusAppointmentParticipant"
					:key="`${item.id}-${column.value}`"
					:column="column"
					:item="item"
					:expanded="expanded"
				></appointment-cell>
			</template>
			<template v-slot:footer.prepend>
				<export
					:name="project.internal_name"
					:columns="columns"
					:rows="downloadRows"
				></export>
			</template>
			<template v-slot:foot v-if="!rotated">
				<tr>
					<td v-for="(column, i) in columns" :key="`footer-${i}`">
						<filter-footer
							v-if="column.filterable"
							:items="rows"
							:column="column"
							v-model="filter[column.value]"
						></filter-footer>
					</td>
				</tr>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import AppointmentCell from "@c/appointment-table/AppointmentCell.vue";
import FilterFooter from "@c/appointment-table/FilterFooter.vue";
import Export from "@c/downloads/Export.vue";
export default {
	name: "AppointmentTable",
	props: {
		id: { type: String },
		headers: {type: Array},
		focusAppointment: { type: String },
		focusAppointmentParticipant: { type: String },
	},
	data: () => {
		return {
			rotated: false,
			sortBy: "start",
			mobileBreakpoint: 600,
			filter: {},
			expanded: false,
		};
	},
	components: { AppointmentCell, Export, FilterFooter },
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		columns() {
			let cols = this.$store.getters['auth/tableHeaders'](this.id);
			if( cols.length ){
				return this.headers.filter( h => cols.includes(h.value) );
			}
			return this.headers;
		},
		appointments() {
			let appointments = this.$store.getters[
				"appointments/activeAppointmentsForProject"
			](this.id);
			return appointments;
		},
		appointmentParticipants() {
			let ids = this.appointments.map((a) => a.id);
			return this.$store.getters.getByKeyIn(
				"appointmentParticipants",
				"appointment",
				ids,
				false
			);
		},
		rows() {
			const self = this;
			let rows = self.appointments.flatMap((appointment) => {
				let appointment_id = appointment.id;
				let appointment_columns = self.headers.filter(
					(c) => c.source == "appointment"
				);
				let participant_columns = self.headers.filter(
					(c) => c.source == "participant"
				);

				let participant_id = null;
				let participants = self.appointmentParticipants.filter(
					(participant) => participant.appointment == appointment.id
				);

				let appointment_object = {
					id: `${appointment_id}-`,
					appointment_id,
					participant_id,
					have_participant: false,
					...Object.fromEntries(
						appointment_columns.map((c) => [
							c.value,
							appointment[c.value] || "",
						])
					),
					i: 0,
					rowspan: 1,
				};

				if (!participants.length) {
					return appointment_object;
				}

				return participants.map((participant, i) => {
					let participant_object = Object.fromEntries(
						participant_columns.map((c) => [
							c.value,
							participant[c.value] || "",
						])
					);
					participant_object.i = i;
					participant_object.have_participant = true;
					participant_object.participant_id = participant.id;
					participant_object.id += participant.id;
					let contacts = this.$store.getters.getParticipantContacts(participant.id);
					if (contacts) {
						participant_object.name = contacts.name;
						participant_object.email = contacts.email;
						participant_object.phone = contacts.phone;
					}
					if (!self.expanded) {
						participant_object.rowspan = participants.length;
					}
					return {
						...appointment_object,
						...participant_object,
					};
				});
			});
			for (var key in self.filter) {
				if (self.filter[key].length) {
					rows = rows.filter((row) => self.filter[key].includes(row[key]));
				}
			}
			return rows;
		},
		downloadRows() {
			const self = this;
			return self.rows.map((row) => {
				return {
					...row,
					start: row.start ? self.mwtimeutils
						.parseDateToMoment(row.start)
						.format("HH:mm DD/MM/YYYY") : "",
				};
			});
		},
		haveFilters() {
			return Object.values(this.filter).flatMap((a) => a).length > 0;
		},
	},
	watch: {
		sortBy(value) {
			if (value) {
				const self = this;
				let col = self.columns.find((a) => a.value == value);
				this.expanded = col.expanded;
			}
		},
		haveFilters(value) {
			if (value) {
				this.expanded = true;
			}
		},
		rotated: {
			handler(v) {
				if (v) {
					this.mobileBreakpoint = 3000;
				} else {
					this.mobileBreakpoint = 600;
				}
			},
		},
	},
	methods: {},
};
</script>
